import { Contract } from "ethers";
import { JsonRpcProvider } from "@ethersproject/providers";

const provider = new JsonRpcProvider("https://bsc-dataseed.binance.org/");

class BscScanApi {
  constructor() {
    this.contract = new Contract(
      "0x94d79c325268c898d2902050730f27a478c56cc1",
      [
        "function balanceOf(address account) external view returns (uint256)",
        "function totalSupply() external view returns (uint256)",
      ],
      provider
    );

    this.key = "GNY6X65WD9GSC1962Z6DKNCAVBJJT9GEZC";

    this.addrs = {
      team: "0x93429c133ab2c4a04d5d2feecd22d5da7127b4e2",
      partner: [
        "0xa6f4eebaa28af404dc67af1eda0b719f6d460e4c",
        "0x161237CECcc6a3EF3c54dBA48290cbfE39a78e1B",
      ],
      companies: [
        "0x0df0c8b86b64dfdb4a7bea1e8b5af5cb5da37a31",
        "0x859197EB8Ed69Ee1C0A7bFa78Cd50e5bf0221194",
        "0xa421B97aA4305b48beBe5c9e356A5AcF0ef576f7",
        "0xAF2a042d460d3450ad4C2152491c70E8fd1f4604",
        "0x2Bd8DC839A297F7C8937303996a2b3e86b3864Df",
      ],
      liquidity: "0xfa44D799bFDF6537a54461859b388b99A75B8FbC",
    };
  }

  async get() {
    return new Promise(async (resolve, reject) => {
      try {
        const team = await this.balanceOf(this.addrs.team);
        let partner = 0;
        for (const addr of this.addrs.partner) {
          partner += await this.balanceOf(addr);
        }
        let companies = 0;
        for (const addr of this.addrs.companies) {
          companies += await this.balanceOf(addr);
        }
        const liquidity = Math.trunc(await this.balanceOf(this.addrs.liquidity));
        const totalSupply = await this.totalSupply();
        const investors = (totalSupply - (team + partner + companies + liquidity)).toFixed(0);
        const burn = Math.trunc(20000000 - totalSupply);
        const circulationSupply = (totalSupply - burn);
        resolve({
          team,
          partner,
          companies,
          liquidity,
          investors,
          burn,
          circulationSupply,
        });
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  }

  async balanceOf(account) {
    try {
      const balance = await this.contract.balanceOf(account);
      return balance / 10 ** 18;
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  async totalSupply() {
    try {
      const total = await this.contract.totalSupply();
      return total / 10 ** 18;
    } catch (e) {
      console.log(e);
      return e;
    }
  }
}

export default BscScanApi;