// @ts-nocheck
import React, { useState, useEffect, useContext, useRef } from "react";
import { getBurns } from '../../scripts/burnTokens.mjs'
import { Line } from 'react-chartjs-2';
import { Language } from '../../Context'
import { useTranslation } from 'react-i18next'
import { color } from "highcharts";

const SupplyChart = () => {

    const { LanguageUse } = useContext(Language)
    const { t } = useTranslation()

    /**** USE EFFECT DISPLAY SUPPLY CHART *****/
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            {
                label: 'Token Supply Over Time',
                data: [],
                fill: false,
                backgroundColor: '#fdfaf6',
                borderColor: '#fdfaf6',
                borderWidth: 3,
                pointBackgroundColor: '#fdfaf6',
                pointBorderColor: '#fdfaf6',
                pointHoverBackgroundColor: '#fdfaf6',
                pointHoverBorderColor: '#fdfaf6',
                pointHoverBorderWidth: 5,
                pointHoverRadius: 5,
                pointStyle: 'circle',
                pointRadius: 4,
                pointHitRadius: 10,
                tension: 0.4,
                lineTension: 0.25,// This adds some curvature to the line
            },
        ],
    })

    const [textToolTipPoint, setTextToolTipPoint] = useState([])
    const [urlBscCsan, setUrlBscScan] = useState([])
    const [totalSupplyBurnt, setTotalSupplyBurnt] = useState([])

    useEffect(() => {
        const fetchTransfers = async () => {
            try {
                const data = await getBurns();
                const line1 = 'Burntoken'
                const line2 = 'TotalSupply'
                const line3 = 'TxHash'
                const line4 = 'ClickToSeeTxHash'
                const textTooltipData =
                    data.map(tx => {
                        return {
                            line1: `${line1}: ${tx.supplyBurnt.toLocaleString('en-US', { maximumFractionDigits: 0 }).replace(/,/g, ' ')}`,
                            line2: `${line2}: ${tx.totalSupply.toLocaleString('en-US', { maximumFractionDigits: 0 }).replace(/,/g, ' ')}`,
                            line3: `${line3}: ${tx.hash}`,
                            line4: `${line4}`,
                        }
                    }
                    );
                setTextToolTipPoint(textTooltipData)

                const urlBscScanData = data.map(tx => tx.url_bscscan)
                setUrlBscScan(urlBscScanData)

                //Total supply IMO burnt
                let sumImo = data.reduce((accumulator, object) => accumulator + object.supplyBurnt, 0)
                let sumImoValue = parseFloat(sumImo);
                let imoSupplyValue = Math.trunc(sumImoValue); // Truncate decimal part

                // Format with space as the thousand separator like US format
                let formattedTotalSupply = imoSupplyValue.toLocaleString('en-US', { maximumFractionDigits: 0 }).replace(/,/g, ' ');
                setTotalSupplyBurnt(formattedTotalSupply)

                const dateLabels = data.map(transfer => (LanguageUse == 'fr') ? transfer.date_fr : transfer.date_en)
                setChartData(prevState => ({
                    ...prevState,
                    labels: dateLabels,
                    datasets: [
                        {
                            ...prevState.datasets[0],
                            data: data.map(transfer => transfer.totalSupply),
                        },
                    ],
                }));
            } catch (error) {
                console.error('Error fetching transfers:', error)
            }
        };

        fetchTransfers()
    }, [chartData, LanguageUse])


    //Translate tooltip text when hover a point in the Supply Chart
    const textToolTipPointTranslated = textToolTipPoint.map((obj) => {
        return {
            'line1': obj.line1.replace('Burntoken', t('Burntoken')),
            'line2': obj.line2.replace('TotalSupply', t('TotalSupply')),
            'line3': obj.line3.replace('TxHash', t('TxHash')),
            'line4': obj.line4.replace('ClickToSeeTxHash', t('ClickToSeeTxHash')),
        }
    })

    //Options to display the chart
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: [`${t('TotalSupplyOverTime').toUpperCase()}`],
                color: '#fdfaf6', // Title text color
                padding: {
                    top: 20,
                    bottom: 40 // Ajout de l'espacement de 20 pixels sous le titre TotalIMOBurnt
                },
            },
            secondTitle: {
                display: true,
                text: [`${t('TotalSupplyOverTime').toUpperCase()}`, '', `${t('TotalIMOBurnt')} : ${totalSupplyBurnt}`],
                color: '#fdfaf6', // Title text color
                padding: {
                    top: 20,
                    bottom: 40 // Ajout de l'espacement de 20 pixels sous le titre TotalIMOBurnt
                },
            },

            tooltip: {
                callbacks: {
                    label: function (context) {
                        const index = context.dataIndex
                        const tooltipText = textToolTipPointTranslated[index]
                        return [tooltipText.line1, tooltipText.line2, tooltipText.line3, tooltipText.line4]
                    }
                }
            }
        },

        scales: {
            x: {
                title: {
                    display: false,
                    text: 'DATE',
                    font: {
                        weight: 'extrabold' // Adjust the font weight here
                    }
                },
                grid: {
                    display: false,
                    drawBorder: false,
                },
                ticks: {
                    color: '#f0f0f0', // X-axis tick color
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'IMO',
                    font: {
                        weight: '600', // Adjust the font weight here
                    }
                },
                grid: {
                    color: '#f0f0f0', // Light grey for Y-axis grid lines
                    borderWidth: 0
                },
                ticks: {
                    color: '#ffffff' // Changer la couleur du texte de l'axe y en blanc
                }
            },
        },
        layout: {
            padding: {
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
            },
        },
        elements: {
            line: {
                borderWidth: 2, // Thicker line
                borderCapStyle: 'round', // Round caps at the end of the line
            },
            point: {
                borderWidth: 2,
                radius: 4,
                hitRadius: 10,
                hoverRadius: 7,
            }
        },
        backgroundColor: '#ffffff', // Light grey background
        onHover: (event, chartElement) => {
            event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
        },
        onClick: (event, elements) => {
            if (elements.length > 0) {
                const firstPoint = elements[0]
                const index = firstPoint.index
                const url = urlBscCsan[index]
                if (url) {
                    window.open(url, '_blank')
                }
            }
        },
    }


    const chartContainer = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            if (chartContainer.current) {
                const containerWidth = chartContainer.current.clientWidth;
            }
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div
            ref={chartContainer}
            style={{
                margin: 'auto',
                width: '93%',
                maxWidth: '900px',
                height: '400px',
                background: 'rgb(29, 32, 45)',
                borderRadius: '15px',
                marginTop: '50px',
                marginBottom: '70px',
                fontFamily: "'new-hero', sans-serif",
            }}
        >
            <Line data={chartData} options={options} />
        </div>
    );
};

export default SupplyChart